import Cookies from 'cookies';
import { IncomingMessage, ServerResponse } from 'http';

import { getCookie } from './cookie';
import { isClientSide } from './client-server';

export const userCookieKey = 'mettzer-user';
export const userTokenKey = 'mettzer-user-jwt';

export const isAdmin = (req?: IncomingMessage, res?: ServerResponse) => {
  let cookieUser;

  if (req && res) {
    const cookies = new Cookies(req, res);
    cookieUser = cookies.get(userCookieKey);
  } else cookieUser = getCookie(userCookieKey);

  if (!cookieUser) return false;

  const user: CompleteUser = JSON.parse(cookieUser);

  if (!user || !user.profile || !user.profile.length || !user.profile.includes('MODAFOCA')) {
    return false;
  }

  return true;
};

export const isInstitutionAdmin = (
  institution: string, req?: IncomingMessage, res?: ServerResponse,
): boolean => {
  let cookieUser;

  if (req && res) {
    const cookies = new Cookies(req, res);
    cookieUser = cookies.get(userCookieKey);
  } else cookieUser = getCookie(userCookieKey);

  if (!cookieUser) return false;

  const user: CompleteUser = JSON.parse(cookieUser);

  if (!user || !user.permissions || !user.permissions.institutionAdmin || (user.permissions.institutionAdmin || '').toUpperCase() !== institution.toUpperCase()) return false;

  return true;
};

const authUrl = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? '/hub/api/auth' : '/api/auth';

export async function authUserClientSide(abortController?: AbortController) {
  if (isClientSide()) {
    const requestInit = abortController ? { signal: abortController.signal } : {};
    fetch(authUrl, requestInit).then((res) => res.json()).then((data) => {
      if (data.shouldRefresh) {
        const event = new CustomEvent('auth', { detail: data });
        document.dispatchEvent(event);
      }
    }).catch(() => {});
  }
}

export default {};
