import { isServerSide } from './client-server';

export const getScreenWidth = (): number => {
  if (isServerSide()) return 0;
  const win = window;
  const doc = document;
  const docElem = doc.documentElement;
  const body = doc.getElementsByTagName('body')[0];
  return win.innerWidth || docElem.clientWidth || body.clientWidth;
};

export const isSmallScreen = () => getScreenWidth() < 500;

export default {};
