import React, { ReactElement } from 'react';
import Head from 'next/head';
import { isClientSide } from '@/lib/client-server';

interface Props {
  title?: string
  description?: string
  image?: string
}

function PageHead({
  title, description, image,
}: Props): ReactElement {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:description" content={description} key="ogdesc" />
      <meta property="og:url" content={isClientSide() ? window.location.href : ''} key="ogurl" />
      <meta property="og:image" content={image} key="ogimage" />
    </Head>
  );
}

PageHead.defaultProps = {
  title: 'Mettzer',
  description: '',
  image: '',
};

export default PageHead;
