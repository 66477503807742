import React, { Fragment, ReactElement } from 'react';
import useTranslation from '@/hooks/useTranslation';
import dynamic from 'next/dynamic';
import classes from './styles.module.scss';

const LanguageSelector = dynamic(() => import('../LanguageSelector'), { ssr: false });

const siteUrl = process.env.NEXT_PUBLIC_SITE_URL;

function Links() {
  const { t } = useTranslation();

  return (
    <Fragment>
      <a target="_blank" href={`${siteUrl}/termos-de-uso`} className="px-4 py-2" rel="noreferrer">{t('footer.terms')}</a>
      <div className={`hidden lg:block ${classes.divider}`} />
      <a target="_blank" href={`${siteUrl}/politica-de-privacidade`} className="px-4 py-2" rel="noreferrer">{t('footer.privacyPolicy')}</a>
      <div className={`hidden lg:block ${classes.divider}`} />
      <a target="_blank" href={`https://transparencyreport.google.com/safe-browsing/search?url=${siteUrl}`} className="px-4 py-2" rel="noreferrer">{t('footer.security')}</a>
      <div className={`hidden lg:block ${classes.divider}`} />
      <a target="_blank" href="http://statuspage.freshping.io/24063-Mettzer" className="px-4 py-2" rel="noreferrer">{t('footer.status')}</a>
    </Fragment>
  );
}

export default function AppFooter(): ReactElement {
  return (
    <footer className="block lg:flex">
      <div className={`container flex flex-col lg:flex-row items-center ${classes.leftContainer}`}>
        <Links />
      </div>
      <div className={`container flex items-center justify-center fontsize-14 ${classes.rightContainer}`}>
        <LanguageSelector />
      </div>
    </footer>
  );
}
