import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Fragment, MouseEvent, ReactElement, useEffect, useRef, useState,
} from 'react';

import { colors } from '@/styles/theme';
import useAuthenticatedUser from '@/hooks/useAuthenticatedUser';
import useTranslation from '@/hooks/useTranslation';
import OutsideClick from '@/components/OutsideClick';
import googleOneTapSign from '@/lib/google-one-tap-sign';
import { deleteCookie } from '@/lib/cookie';
import { userCookieKey } from '@/lib/user';
import classes from './styles.module.scss';

const itemsGap = { gap: '2rem' };

function classNames(..._classes: string[]) {
  return _classes.filter(Boolean).join(' ');
}

interface MenuItem {
  label: string
  href: string
  target?: string
  onClick?: () => void
}

interface MobileButtonProps {
  open: boolean
}

interface MenuProps {
  authenticatedUser: CompleteUser | null | undefined
}

interface RightButtonsProps {
  authenticatedUser: CompleteUser | null | undefined
}

const editorUrl = process.env.NEXT_PUBLIC_EDITOR_URL || '';
const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || '';
const checkoutUrl = `${editorUrl}/#/subscribe`;
const authUrl = process.env.NEXT_PUBLIC_AUTH_URL || '';
const blogUrl = process.env.NEXT_PUBLIC_BLOG_URL || '';
const helpCenterUrl = process.env.NEXT_PUBLIC_HELP_URL || '';

const solutionsItems: MenuItem[] = [
  { label: 'header.students', href: `${siteUrl}/editor-de-texto` },
  { label: 'header.teachers', href: `${siteUrl}/professores` },
  { label: 'header.universities', href: `${siteUrl}/universidades` },
];

const editorItems: MenuItem[] = [
  { label: 'header.myWorks', href: `${editorUrl}/#/app` },
  { label: 'header.myRefs', href: `${editorUrl}/#/app/references-library` },
];

const menuItems: MenuItem[] = [
  { label: 'header.tryForFree', href: checkoutUrl },
  { label: 'header.students', href: `${siteUrl}/editor-de-texto` },
  { label: 'header.teachers', href: `${siteUrl}/professores` },
  { label: 'header.universities', href: `${siteUrl}/universidades` },
  { label: 'header.blog', href: blogUrl },
  { label: 'header.plansAndPrices', href: checkoutUrl },
  { label: 'header.login', href: `${authUrl}?urlAlias=hub`, target: '_self' },
];

function logout() {
  deleteCookie({ name: userCookieKey });
}

const authenticatedMenuItems: MenuItem[] = [
  { label: 'header.myWorks', href: `${editorUrl}/#/app`, target: '_self' },
  { label: 'header.myRefs', href: `${editorUrl}/#/app/references-library`, target: '_self' },
  { label: 'header.hub', href: '/' },
  { label: 'header.helpCenter', href: helpCenterUrl },
  {
    label: 'logout', href: `${authUrl}/logout`, target: '_self', onClick: logout,
  },
];

function Logo() {
  const homeLink = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? '/hub' : '/';
  return (
    <Link href={homeLink} passHref>
      <a>
        <Image
          className="cursor-pointer"
          src={process.env.NEXT_PUBLIC_LOGO_URL || ''}
          alt="Mettzer"
          width="162px"
          height="50px"
        />
      </a>
    </Link>
  );
}

function SolutionsDropdown() {
  const { t } = useTranslation();
  const [solutionsOpened, setSolutionsOpened] = useState(false);

  const [solutionsText, setSolutionsText] = useState('');

  useEffect(() => {
    setSolutionsText(t('header.solutions'));
  }, [t('header.solutions')]);

  const toggleSolutionsDrop = (opened: boolean, e: MouseEvent) => {
    setSolutionsOpened(opened);
    e.preventDefault();
  };

  return (
    <OutsideClick callback={() => setSolutionsOpened(false)}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu as="a" href="#" className="inline-flex justify-center w-full px-4 py-2 cursor-pointer" onClick={(e: MouseEvent) => toggleSolutionsDrop(!solutionsOpened, e)}>
            {solutionsText}
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu>
        </div>
        <Transition
          show={solutionsOpened}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {solutionsItems.map((item) => (
                <Menu.Item key={item.href} onClick={() => setSolutionsOpened(false)}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(item.label)}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </OutsideClick>
  );
}

function EditorDropdown() {
  const { t } = useTranslation();
  const [editorOpened, setEditorOpened] = useState(false);

  const toggleEditorsDrop = (opened: boolean, e: MouseEvent) => {
    setEditorOpened(opened);
    e.preventDefault();
  };

  return (
    <OutsideClick callback={() => setEditorOpened(false)}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu as="a" href="#" className="inline-flex justify-center w-full px-4 py-2 cursor-pointer" onClick={(e: MouseEvent) => toggleEditorsDrop(!editorOpened, e)}>
            {t('header.editor')}
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu>
        </div>
        <Transition
          show={editorOpened}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {editorItems.map((item) => (
                <Menu.Item key={item.href} onClick={() => setEditorOpened(false)}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(item.label)}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </OutsideClick>
  );
}

function MobileMenu({ authenticatedUser }: MenuProps) {
  const { t } = useTranslation();

  return (
    <Disclosure.Panel className="lg:hidden">
      <div className="px-2 pt-2 pb-3" style={{ borderTop: `1px solid ${colors.mercury}` }}>
        {(authenticatedUser ? authenticatedMenuItems : menuItems).map(({
          label, href, target = '_blank', onClick,
        }) => (
          <div key={label + href}>
            <Disclosure.Button
              as="a"
              href={href}
              className="block px-4 py-2"
              target={target}
              onClick={onClick}
            >
              {t(label)}
            </Disclosure.Button>
          </div>
        ))}
      </div>
    </Disclosure.Panel>
  );
}

function MobileMenuButton({ open }: MobileButtonProps) {
  return (
    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md">
      <span className="sr-only">Open main menu</span>
      {open ? (
        <XIcon className="block h-6 w-6" aria-hidden="true" />
      ) : (
        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
      )}
    </Disclosure.Button>
  );
}

function RightButtons({ authenticatedUser }: RightButtonsProps) {
  const { asPath } = useRouter();
  const redirectUrl = encodeURIComponent(asPath);
  const { t } = useTranslation();

  return (
    <div className="hidden lg:flex ml-auto items-center" style={itemsGap}>
      {authenticatedUser ? (
        <Fragment>
          <a href="https://ajuda.mettzer.com" target="_blank" rel="noreferrer">
            <button type="button" className="button button-primary">{t('header.helpCenter')}</button>
          </a>
          <a onClick={logout} href={`${authUrl}/logout`} rel="noreferrer">
            <button type="button" className="button button-secondary">{t('header.logout')}</button>
          </a>
        </Fragment>
      ) : (
        <Fragment>
          <a href={`${authUrl}?urlAlias=hub&redirectUrl=${redirectUrl}`} rel="noreferrer">
            <button type="button" className="button button-secondary">login</button>
          </a>
          <a href={siteUrl} target="_blank" rel="noreferrer">
            <button type="button" className="button button-primary">{t('header.tryForFree')}</button>
          </a>
        </Fragment>
      )}
    </div>
  );
}

function MenuLinks({ authenticatedUser }: MenuProps) {
  const { t } = useTranslation();

  return (
    <div className={`flex items-center justify-center h-full ${classes.menuLinks}`} style={itemsGap}>
      {authenticatedUser ? (
        <Fragment>
          <EditorDropdown />
          <Link href="/">{t('header.hub')}</Link>
        </Fragment>
      ) : (
        <Fragment>
          <SolutionsDropdown />
          <a href={checkoutUrl} target="_blank" rel="noreferrer">{t('header.plansAndPrices')}</a>
          <a href={blogUrl} target="_blank" rel="noreferrer">{t('header.blog')}</a>
        </Fragment>
      )}
    </div>
  );
}
export default function AppHeader(): ReactElement {
  const authenticatedUser = useAuthenticatedUser();
  const userLoaded = useRef(false);

  useEffect(() => {
    if (!userLoaded.current) {
      userLoaded.current = true;
      return;
    }

    const { google } = global as any;

    if (!google) return;

    googleOneTapSign();
  }, [authenticatedUser]);

  return (
    <header>
      <Disclosure as="nav">
        {({ open }) => (
          <Fragment>
            <div className="max-w-7xl mx-auto px-2 lg:px-8">
              <div className="relative flex items-center justify-between h-20">
                <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                  <MobileMenuButton open={open} />
                </div>
                <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <Logo />
                  </div>
                  <div className="hidden lg:block lg:ml-6">
                    <MenuLinks authenticatedUser={authenticatedUser} />
                  </div>
                </div>
                <RightButtons authenticatedUser={authenticatedUser} />
              </div>
            </div>
            <MobileMenu authenticatedUser={authenticatedUser} />
          </Fragment>
        )}
      </Disclosure>
    </header>
  );
}
