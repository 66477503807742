import { useState, useEffect, useCallback } from 'react';

import { getCookie } from '@/lib/cookie';
import { userCookieKey } from '@/lib/user';
import { isClientSide } from '@/lib/client-server';

export default function useAuthenticatedUser(): CompleteUser | null | undefined {
  const [user, setUser] = useState<CompleteUser | null | undefined>(undefined);

  const authEventHandler = useCallback((event: CustomEvent) => {
    if (event.detail.shouldRefresh) {
      const userString = getCookie(userCookieKey);
      setUser(userString.length ? JSON.parse(userString) : null);
    }
  }, []) as EventListener;

  useEffect(() => {
    if (isClientSide()) {
      document.addEventListener('auth', authEventHandler);
    }
    const userString = getCookie(userCookieKey);
    setUser(userString.length ? JSON.parse(userString) : null);

    return () => {
      if (isClientSide()) {
        document.removeEventListener('auth', authEventHandler);
      }
    };
  }, []);

  return user;
}
