import { accounts, CredentialResponse, PromptMomentNotification } from 'google-one-tap';

export default function googleOneTapSign(): void {
  const { google } = global as any;
  const ggAccounts: accounts = google.accounts;
  const { id } = ggAccounts;
  const { location } = window;
  const { pathname, search } = location;

  id.initialize({
    client_id: process.env.NEXT_PUBLIC_GOOGLE_SIGN_CLIENT_ID,
    cancel_on_tap_outside: false,
    callback: (response: CredentialResponse) => {
      const { credential } = response;
      const redirectUrl = encodeURIComponent(pathname + search);

      window.location.href = `${process.env.NEXT_PUBLIC_AUTH_URL}/auth${process.env.NEXT_PUBLIC_GOOGLE_SIGN_CLIENT_ID}/${credential}
       ?urlAlias=hub&redirectUrl=${redirectUrl}`;
    },
  });

  id.prompt((notification: PromptMomentNotification) => notification);
}
