import React, { Fragment, ReactElement } from 'react';
import Head from 'next/head';

import AppFooter from '@/components/AppFooter';
import { isClientSide } from '@/lib/client-server';
import AppHeader from '@/components/AppHeader';

interface Props {
  children: ReactElement
}

function MainLayout({ children }: Props): ReactElement {
  const isOriginUrl = isClientSide() && window.location.origin === 'https://hub.mettzer.com';

  return (
    <Fragment>
      <Head>
        <meta property="og:site_name" content="Mettzer" key="ogsitename" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="google-site-verification" content="7W-eA540FaF7Tuqu57lu2TyYiboR2-XMcogmkeJrffs" />
        {isOriginUrl && <meta name="robots" content="noindex" />}
        <link rel="icon" href="https://s3.sa-east-1.amazonaws.com/mettzer.com/img/logo-loader.png" />
      </Head>
      <AppHeader />
      {children}
      <AppFooter />
    </Fragment>
  );
}

export default MainLayout;
