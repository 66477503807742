import React, {
  useRef, useEffect, ReactElement, RefObject,
} from 'react';

/**
 * Hook that captures outside clicks of the passed ref
 */
function useOutsideAlerter(ref: RefObject<HTMLElement>, callback: () => void) {
  useEffect(() => {
    /**
     * Handle click on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains((event.target as Node))) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

interface Props {
  callback: () => void
  children: ReactElement
}

/**
 * Component that captures outside clicks
 */
export default function OutsideClick({ callback, children }: Props): ReactElement {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, callback);
  return <div ref={wrapperRef}>{children}</div>;
}
